<template>
  <div class="page">
    <img src="@i/login/login.png" class="left-img" />
    <div class="right-content-container">
      <div class="g-flex-row">
        <span class="title">登录抖脉账号</span>
      </div>
      <div class="content-container" v-if="isLogin">
        <div class="content-container-form" v-if="isPassword">
          <a-form>
            <a-form-item
              label
              :validate-status="params.mobilePhone.help ? 'error' : ''"
              :help="params.mobilePhone.help"
              :label-col="{ span: 0 }"
              :wrapper-col="{ span: 16 }"
            >
              <a-input
                placeholder="请输入手机号"
                v-model="params.mobilePhone.value"
                style="width:336px;height:44px;border-radius:6px"
                class="mt20"
              ></a-input>
            </a-form-item>
            <a-form-item
              label
              :validate-status="params.password.help ? 'error' : ''"
              :help="params.password.help"
              :label-col="{ span: 0 }"
              :wrapper-col="{ span: 16 }"
            >
              <a-input
                @keyup.enter="onLoginClick"
                placeholder="请输入密码"
                type="password"
                v-model="params.password.value"
                @blur="onCheckBlur"
                style="width:336px;height:44px;border-radius:6px"
                class="mt20"
              ></a-input>
            </a-form-item>
          </a-form>
          <span class="registered-button" @click="onLoginClick">登录</span>
        </div>
        <div class="content-container-form" v-if="!isPassword">
          <a-form>
            <a-form-item
              label
              :validate-status="params.mobilePhone.help ? 'error' : ''"
              :help="params.mobilePhone.help"
              :label-col="{ span: 0 }"
              :wrapper-col="{ span: 16 }"
            >
              <a-input
                placeholder="请输入手机号"
                v-model="params.mobilePhone.value"
                style="width:336px;height:44px;border-radius:6px"
                class="mt20"
              ></a-input>
            </a-form-item>
            <a-form-item
              label
              :validate-status="params.captcha.help ? 'error' : ''"
              :help="params.captcha.help"
              :label-col="{ span: 0 }"
              :wrapper-col="{ span: 16 }"
            >
              <div class="captcha-container mt20">
                <a-input
                  @keyup.enter="onLoginClick"
                  placeholder="请输入验证码"
                  style="height:44px;border-radius:6px 0 0 6px;"
                  v-model="params.captcha.value"
                ></a-input>
                <span
                  class="captcha-button"
                  @click="onCaptchaClick"
                  v-if="waitTime > 59"
                  >发送验证码</span
                >
                <span
                  class="captcha-count-down-button g-disable-button"
                  style="border-radius:0 6px 6px 0"
                  v-else
                  disable
                  >{{ waitTime }}s后可重新获取</span
                >
              </div>
            </a-form-item>
          </a-form>
          <span class="registered-button" @click="onLoginClick">登录</span>
        </div>
      </div>
      <div class="buttons mt30">
        <div class="g-flex-row">
          <span class="cl-333">没有账号?</span>
          <router-link to="/registered">
            <span class="text-cl-pointer ml5">注册</span>
          </router-link>
        </div>
        <div class="g-flex-row mt5">
          <span
            class="text-cl-pointer pointer"
            v-if="!isPassword"
            @click="isPassword = true"
            >密码登录</span
          >
          <span
            class="text-cl-pointer pointer"
            v-if="isPassword"
            @click="isPassword = false"
            >验证码登录</span
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Statistical from '@l/statistical'
import regular from '@l/regular'
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['GImgQR'])
  },
  name: 'login',
  data () {
    return {
      isPassword: true,
      isLogin: true,
      waitTime: 60,
      imgQRCode: '',
      params: {
        mobilePhone: {
          value: '',
          help: ''
        },
        password: {
          value: '',
          help: ''
        },
        captcha: {
          value: '',
          help: ''
        }
      }
    }
  },
  created () {},
  destroyed () {},
  methods: {
    // ------------------ 点击方法
    onCaptchaClick () {
      this.sendLoginCaptcha()
    },
    onCheckBlur () {
      if (regular.regPhone.test(this.params.mobilePhone.value)) {
        this.params.mobilePhone.help = ''
      } else {
        this.params.mobilePhone.help = '请输入正确的手机号码'
      }
    },
    onLoginClick () {
      var withoutError = true
      if (!regular.regPhone.test(this.params.mobilePhone.value)) {
        withoutError = false
        this.params.mobilePhone.help = '请输入正确格式的手机号码'
      }
      if (!this.params.mobilePhone.value) {
        withoutError = false
        this.params.mobilePhone.help = '请输入手机号码'
      }
      if (this.isPassword) {
        if (!regular.regPassword.test(this.params.password.value)) {
          withoutError = false
          this.params.password.help = '请输入正确格式的密码'
        }
        if (!this.params.password.value) {
          withoutError = false
          this.params.password.help = '请输入密码'
        }
      } else {
        if (!regular.regCaptcha.test(this.params.captcha.value)) {
          withoutError = false
          this.params.captcha.help = '请输入正确格式的验证码'
        }
        if (!this.params.captcha.value) {
          withoutError = false
          this.params.captcha.help = '请输入验证码'
        }
      }
      if (!withoutError) {
        return
      }
      this.params.mobilePhone.help = ''
      this.params.captcha.help = ''
      this.params.password.help = ''
      Statistical.login.tologin()
      if (this.isPassword) {
        this.getSalt()
      } else {
        this.reqLogin()
      }
    },
    // ------------------ 请求方法
    sendLoginCaptcha () {
      if (!regular.regPhone.test(this.params.mobilePhone.value)) {
        this.params.mobilePhone.help = '请输入正确格式的手机号码'
        return
      }
      this.$api.account
        .sendLoginCaptcha({ mobilePhone: this.params.mobilePhone.value })
        .then(res => {
          this.countdown()
        })
        .catch(res => {})
    },
    getSalt () {
      this.$api.account
        .getSalt({ mobilePhone: this.params.mobilePhone.value })
        .then(res => {
          this.loginByPassword(res)
        })
        .catch(res => {
          if (res > 0) {
            this.isError = true
          }
        })
    },
    loginByPassword (res) {
      this.$api.account
        .passwordLogin(
          this.params.mobilePhone.value,
          this.params.password.value,
          res.salt
        )
        .then(res => {
          this.$router.push({ path: '/index' })
        })
        .catch(res => {})
    },
    reqLogin () {
      this.$api.account
        .reqLogin({
          mobilePhone: this.params.mobilePhone.value,
          captcha: this.params.captcha.value
        })
        .then(res => {
          this.$router.push({ path: '/index' })
        })
        .catch(res => {})
    },
    // ------------------ 其它
    countdown () {
      const _this = this
      if (this.waitTime === 0) {
        this.waitTime = 60
      } else {
        this.waitTime--
        setTimeout(function () {
          _this.countdown()
        }, 1000)
      }
    }
  }
}
</script>
<style lang="less" scoped>
.page {
  display: flex;
  flex-direction: row;
  height: 100vh;
}
.left-img {
  height: 100%;
}
.right-content-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
  position: relative;
  background: #fff;
  .index-button {
    color: #4690ff;
    font-size: 16px;
    position: absolute;
    top: 47px;
    right: 44px;
    z-index: 2;
    .pointer;
  }
}
.content-container {
  width: 568px;
  border-radius: 4px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 14px;
  }
  .captcha-container {
    .g-flex-row;
    width: 336px;
  }
  .captcha-button {
    height: 44px;
    line-height: 44px;
    padding: 0 10px;
    border-radius: 0 6px 6px 0;
    border: 1px solid #2878ff;
    // border-radius: 2px;
    color: #2878ff;
    flex-shrink: 0;
    .pointer;
  }
  .captcha-count-down-button {
    height: 44px;
    line-height: 44px;
    padding: 0 10px;
    border: 1px solid rgb(217, 217, 217);
    border-radius: 2px;
    flex-shrink: 0;
  }
  .registered-button {
    height: 44px;
    line-height: 44px;
    width: 336px;
    padding: 0 10px;
    background: @cl-main;
    border-radius: 6px;
    color: #ffffff;
    flex-shrink: 0;
    margin-top: 28px;
    .ft16;
    .pointer;
    .g-flex-center;
  }
  .registered-disable-button {
    height: 44px;
    line-height: 44px;
    width: 336px;
    padding: 0 10px;
    border-radius: 2px;
    flex-shrink: 0;
    margin-top: 28px;
    .ft16;
    .g-flex-center;
  }
}
.img-container {
  height: 180px;
  width: 568px;
  margin-top: 60px;
  background-image: url(../../assets/images/login/banner.png);
  position: relative;
  .qr-code {
    height: 111px;
    width: 111px;
    position: absolute;
    top: 26px;
    right: 36px;
  }
}
.router-button {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
}
.record-container {
  color: #999;
  margin-top: 110px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
.tabs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 20px;
  width: 100%;
  height: 52px;
  .tab {
    flex: 1;
    display: flex;
    flex-direction: column;
    color: #999999;
    font-size: 16px;
    margin-bottom: 3px;
  }
  .select-tab {
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    line-height: 20px;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .bottom-line {
    width: 60px;
    height: 3px;
    background: #3d8fff;
  }
  .tab-text {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 48px;
    .pointer;
  }
}
.buttons {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title {
  font-size: 22px;
  color: #868998;
}
</style>
